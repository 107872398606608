import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Auth
import { AuthGuard } from './shared/services/auth/auth.guard';

// Components
import { HomeComponent } from './home/home.component';
import { SlotsReportComponent } from './slot-management/slots-report/slots-report.component';
import { SlotsDiagnosticsToolComponent } from './slot-management/slots-diagnostics/slots-diagnostics-tool.component';

import { EnvService } from './shared/context/env.service';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    canActivate: [AuthGuard]
  },
  { path: 'store',
    loadChildren: () => import('./store-management/store-operations.module').then(m => m.StoreOperationsModule),
    canActivate: [AuthGuard]
  },
  { path: 'slot',
    loadChildren: () => import('./slot-management/slots.module').then(m => m.SlotsModule),
    canActivate: [AuthGuard]
  },
  { path: 'promocode-upload',
    loadChildren: () => import('./promocode-upload/promocode-upload.module').then(m => m.PromoUploadModule),
    canActivate: [AuthGuard]
  },
  { path: 'levers',
    loadChildren: () => import('./levers-management/levers.module').then(m => m.LeversModule),
    canActivate: [AuthGuard]
  },
  { path: 'promise-platform',
    loadChildren: () => import('./promise-platform/promise-platform.module').then(m => m.PromisePlatformModule),
    canActivate: [AuthGuard]
  },
  { path: 'help',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'slot/slotcapacity', component: SlotsReportComponent
  },
  {
    path: 'slot/slot-diagnostics', component: RedirectComponent
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
