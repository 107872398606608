import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvService } from '../shared/context/env.service';

@Component({
  selector: 'app-redirect-to-ffc',
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router, private envService: EnvService) {}

    ngOnInit() {
        window.location.href = this.envService.environment.FFC_APP_URL + '/promise-management/eoa-react/eop/dcp-control-center/slot-diagnostics-tool';
    }

}